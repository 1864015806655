<template>
  <div style="background: #fff;color: #000;height: 100%;">
    <van-nav-bar title="门店预约" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <div style="margin-top:46px;height: 100%;background: #fff;">
      <div class="shuoming" v-if="type == 0">
        预约时间
        <span style="color: #FFA500;">20分钟内</span>到店可优先安装，如预约的工位正在施工，
        <span style="color: #FFA500;">可优先排队</span>（线上开发部分预约工位，如果全部约满，可直接到店取号排队）
      </div>
      <span></span>
      <div class="xuan_a" style="margin-top: 12px;">
        <span class="xin_b left">
          <img class="mendiantu" v-if="shangjia.doorPic" :src="shangjia.doorPic" alt />
          <img class="mendiantu" v-else src="../../assets/img/mendian.png" alt />
        </span>
        <div class="left dian_ha">
          <div class="dian_h1">{{xqlist.garageName}}</div>
          <div class="dian_h2">
            <van-icon name="underway-o" style="vertical-align: middle" />
            <span style="vertical-align: middle;margin-left: 2px;">预约时间：</span>
            <span style="vertical-align: middle">{{xqlist.visitDate}}</span>
          </div>
          <div class="dian_h3">
            <van-icon name="contact" style="vertical-align: middle" />
            <span style="vertical-align: middle;margin-left: 2px;">预约客户：</span>
            <span style="vertical-align: middle">{{xqlist.userName}}</span>
          </div>
        </div>
      </div>
      <div style="height: 8px;background: #f5f5f5;"></div>
      <div class="heng">
        <div class="henc">{{xqlist.nianyue +' ' + xqlist.week}}</div>
        <div class="hen"></div>
      </div>

      <van-row gutter="10" class="sjs">
        <van-col class="shijianc" span="6" v-for="(item,idx) in SJlist" :key="idx">
          <div
            class="shijian"
            :class="{activc:(item.timeSlot == xqlist.shijian),activcd:(item.station==0)}"
          >
            <p :style="{lineHeight:(item.timeSlot != xqlist.shijian?'':'')}" >{{item.timeSlot}}</p>
            <p class="yuyue" v-if="item.timeSlot == xqlist.shijian">已预约</p>

            <p class="yuyue" v-else :style="{color:(item.station==0?'#999':'')}">
              <span v-if="appointmentId">{{item.station > 0?'剩余工位'+item.station:'已约满'}}</span>
            </p>
          </div>
        </van-col>
      </van-row>

      <div class="xianmu" v-if="type == 1">
        <span>预约项目：</span>
        <span
          @click="xmclick(item.id)"
          class="xianmu_a"
          v-for="(item,idx) in xiangmulist"
          :key="idx"
          :class="item.name == xqlist.items[0]?'activ':''"
        >{{item.name}}</span>
      </div>
      <div class="xianmu" v-if="type == 2 || type == 3 || type == 0">
        <span>预约项目：</span>
        <span class="xianmu_a activ" v-for="(item,idx) in xqlist.items" :key="idx">{{item}}</span>
      </div>
      <div class="xianmuc" >
        <div class="left" style="line-height: 14px;">备注：</div>
        <textarea class="left iptt" placeholder="请输入..." v-model="xqlist.remark"></textarea>
      </div>
      <!-- <van-empty v-if="list.length < 1" description="暂无数据~" /> -->
    </div>
    <van-goods-action>
      <van-goods-action-icon icon="phone-o" text="联系门店">
        <!-- <a :href="'tel:'+18318224189">联系门店</a> -->
        <a :href="'tel:'+shangjia.tel1">联系门店</a>
      </van-goods-action-icon>
      <van-goods-action-button type="default" plain text="取消预约" @click="quxiao" />
    </van-goods-action>
  </div>
</template>

<script>
import orderModel from "../../api/order";
import utils from "../../assets/utils";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  //   mixins: [preventBack], //注入
  components: {},
  data() {
    return {
      list: [],
      SJlist: [],
      xqlist: {},
      xiangmulist: [
        { id: 1, name: "美容洗车" },
        { id: 2, name: "汽车保养" },
        { id: 3, name: "汽车维修" }
      ],
      id: this.$route.query.id,
      type: this.$route.query.type,
      appointmentId: this.$route.query.appointmentId,
      xmid: 0,
      timeSlot: 0,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia"))
        ? JSON.parse(sessionStorage.getItem("shangjia"))
        : "",
      active: "0"
    };
  },
  methods: {
    onLoad() {
      const data = {
        gid: this.shangjia.id,
        appointmentId: this.appointmentId,
        // gid: 1,
        time: this.xqlist.ri
      };
      orderModel
        .station(data)
        .then(e => {
          this.SJlist = e.data;
        })
        .catch(() => loading.clear());
    },

    xmclick(id) {
      this.xmid = id;
    },
    etNowTimes() {
      let myDate = new Date();
      // console.log(myDate)
      let wk = myDate.getDay();
      //   let yy = String(myDate.getFullYear())
      //   let mm = myDate.getMonth() + 1
      //   let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
      //   let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
      //   let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
      //   let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
      let weeks = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      let week = weeks[wk];
      console.log(week);
      this.nowWeek = week;
    },
    getDay(day) {
      var today = new Date();
      console.log(today);
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      console.log(targetday_milliseconds);
      today.setTime(targetday_milliseconds); //注意，这行是关键代码
      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      var shijian = tYear + "-" + tMonth + "-" + tDate;
      var date1 = new Date(shijian);
      let wk = date1.getDay();
      let weeks = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      let week = weeks[wk];
      var yue = tMonth + "-" + tDate;
      var lis = {
        yue: yue,
        week: week
      };
      console.log(week);
      console.log(date1);
      //   return tYear + "-" + tMonth + "-" + tDate;
      this.list.push(lis);
      return tMonth + "-" + tDate + " " + week;
    },
    doHandleMonth(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
    quxiao() {
      let data = {
        id: this.xqlist.id,
        status: 0,
        type: this.type
      };

      orderModel
        .yuyuexiugai(data)
        .then(e => {
          this.$toast("取消成功！");
          setTimeout(() => {
            this.$router.go(-1);
          }, 700);
        })
        .catch(() => loading.clear());
    },
    namelist() {
      const data = {
        id: this.id
      };
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      orderModel
        .yuyuexianqin(data)
        .then(e => {
          loading.clear();
          this.xqlist = e.data;
          var time1 = e.data.visitDate.replace(/-/g, "/");
          var date1 = new Date(time1);
          let wk = date1.getDay();
          let weeks = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
          this.xqlist.week = weeks[wk];
          this.xqlist.nianyue = e.data.visitDate.substring(5, 10);
          this.xqlist.ri = e.data.visitDate.substring(0, 10);
          this.xqlist.shijian = e.data.visitDate.substr(-8);
          this.xqlist.shijian = this.xqlist.shijian.substring(0, 5);
          this.timeSlot = this.xqlist.shijia;
          this.onLoad();
          console.log(this.xqlist);
        })
        .catch(() => loading.clear());
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    // console.log("昨天：", this.getDay(-1));
    this.namelist();
    // this.getDay(0);
    // this.getDay(1);
    // this.getDay(2);
    // this.getDay(3);

    // console.log("今天：", this.getDay(2));
    // console.log("明天：", this.getDay(3));
    // this.etNowTimes()
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.xuan_a {
  font-size: 13px;
  overflow: hidden;
  //   line-height: 35px;
  margin: 5px 0;
}
.heng {
  position: relative;
  font-size: 14px;
  font-weight: bold;
  line-height: 40px;
  border-bottom: 1px solid #f5f5f5;
  padding: 0 12px;
}
.henc {
  display: inline-block;
}
.activcd {
  background: #f3f3f3;
  color: #999;
}
.hen {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 5px;
  margin-left: 27px;
  height: 2px;
  width: 40px;
  background: #ee0a24;
}
.van-button {
  border: 1px solid #c8c9cc;
  border-radius: 5px;
}
.shijian {
  font-size: 12px;
  text-align: center;
  border: 1px solid #f5f5f5;
  padding: 6px 0;
  border-radius: 5px;
  height: 50px;
}
.shijian p {
  text-align: center;
  line-height: 16px;
}
.xianmu {
  font-size: 14px;
  padding: 0 12px;
  overflow: hidden;
}
.shuoming {
  padding: 8px 12px;
  font-size: 12px;
  line-height: 18px;
  background: #ffffe0;
  color: #333;
}
.xianmuc {
  font-size: 14px;
  padding: 0 12px;
  overflow: hidden;
  margin-top: 15px;
}
.iptt {
  width: 85%;
  height: 120px;
  border: 1px solid #999;
}
.xianmu .activ {
  background: red;
  color: #fff;
}

.shijianc .activc {
  background: red;
  color: #fff;
}
.activc p {
  color: #fff;
}
.xianmu_a {
  display: inline-block;
  border: 1px solid #f5f5f5;
  margin-left: 6px;
  font-size: 13px;
  padding: 0 5px;
  color: #666;
  border-radius: 4px;
}
.van-tab {
  // color: #000;
}
.yuyue {
  font-size: 10px;
  color: #666;
}
.van-row {
  margin: 15px 12px;
}
.xin_b {
  margin-top: 12px;
  margin-left: 12px;
  font-size: 13px;
}
.mendiantu {
  width: 80px;
  height: 70px;
}
.dian_h1 {
  font-size: 16px;
  font-weight: bold;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 17px;
}
.dian_h3 {
  color: #666;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.van-goods-action-button--last {
  border-radius: 20px;
}
.dian_h2 {
  color: #666;
  line-height: 14px;
  font-size: 12px;
  margin-top: 14px;
}
.dian_ha {
  margin-left: 10px;
  font-size: 12px;
  margin-top: 12px;
  width: 210px;
}
.shijianc:nth-child(n + 5) {
  margin-top: 5px;
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}
.van-tabs__wrap {
  border-bottom: 1px solid #f5f5f5;
}
.van-tab {
  font-weight: bold;
}
.van-goods-action-button--last {
  border-radius: 5px;
}
</style>